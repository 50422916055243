<template>
    <div class="wrap">
        <AlertModal v-if="isAlertModal" :alertText="'저장되었습니다'" @closeModal="closeAlertModal()" />
        <!-- pc -->
        <div class="space_pc">
            <section class="main myspace w_940">
                <div class="main_box">
                    <div class="main_box_title">
                        <h1 style="margin-bottom: 0px;">이용 목적 설정</h1>
                        <div>
                            <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg"
                                 @click="clickPre()">
                                <path
                                    d="M9.81055 7.39387L16.8109 0.393555L18.8105 2.39324L11.8102 9.39355L18.8105 16.3939L16.8109 18.3936L9.81055 11.3932L2.81023 18.3936L0.810547 16.3939L7.81086 9.39355L0.810547 2.39324L2.81023 0.393555L9.81055 7.39387Z"
                                    fill="white" />
                            </svg>
                        </div>
                    </div>
                    <div class="cs_main">
                        <div class="myspace_content frenchise" id="frenchise1">
                            <div class="alarm_setting no_pd">
                                <p style="margin-bottom: 0px;">이용 목적 매번 묻기</p>
                                <div class="alarm_btn_box">
                                    <div class="email_alarm">
                                        <div class="alarm_box" id="alarm_box1">
                                            <span :class="{ active1: carPurposeCheck === 'Y' }" @click="updateCuration()"></span>
                                            <span class="move" :class="{ active1_1: carPurposeCheck === 'Y' }" @click="updateCuration()"></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="auto_play_notice auto_play_notice1">
                                <h5>유의사항</h5>
                                <ul>
                                    <li><span style="margin-bottom: 0px;"></span>
                                        <p style="margin-bottom: 0px;">이용 목적 매번 묻기를 ON한 경우, 플레이어 이용시마다 이용목적을 물어 목적별 최적의 음악 큐레이션이 진행됩니다.</p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
        <!-- 모바일 -->
        <div class="space_mo">
            <!-- <div class="back_dim"></div> -->
            <div class="mo_space_header">
                <div class="mo_header_box">
                    <a>
                        <img src="/media/images/icon_back_24.svg" @click="clickPre()">
                    </a>
                    <h2>이용 목적 설정</h2>
                    <div></div>
                </div>
            </div>
            <section class="main myspace auto_play">
                <div class="main_box">
                    <div class="cs_main">
                        <div class="myspace_content frenchise" id="frenchise2">
                            <div class="alarm_setting no_pd">
                                <p>이용 목적 매번 묻기</p>
                                <div class="alarm_btn_box">
                                    <div class="email_alarm">
                                        <div class="alarm_box" id="alarm_box2">
                                            <span :class="{ active1: carPurposeCheck === 'Y' }" @click="updateCuration()"></span>
                                            <span class="move" :class="{ active1_1: carPurposeCheck === 'Y' }" @click="updateCuration()"></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="auto_play_notice auto_play_notice2">
                                <h5>유의사항</h5>
                                <ul>
                                    <li><span></span>
                                        <p style="margin-bottom: 0px;">이용 목적 매번 묻기를 ON한 경우, 플레이어 이용시마다<br />이용목적을 물어 목적별 최적의 음악 큐레이션이 진행됩니다.
                                        </p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
        <div class="carpurpose_form">
            <button @click="updateCurationApi">확인</button>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import VueCookies from "vue-cookies";
import $ from 'jquery';
import AlertModal from "@/components/modal/Space/AlertModal.vue";
export default {
  data () {
    return {
      carPurposeCheck: 'N',
      isAlertModal: false
    };
  },
  components: {
    AlertModal
  },
  methods: {
    closeAlertModal () {
      this.isAlertModal = false;
      this.$router.push({ name: "MySpace" }).catch(() => { });
    },
    updateCuration () {
      this.carPurposeCheck === 'Y' ? this.carPurposeCheck = 'N' : this.carPurposeCheck = 'Y';
    },
    updateCurationApi () {
      var spaceId = localStorage.getItem("spaceId");
      var carPurposeCheck = this.carPurposeCheck;
      var aToken = VueCookies.get("aToken");
      var headers = { "X-AUTH-TOKEN": aToken };

      axios
        .post("/api/profile/updateCurationInfo", { spaceId, carPurposeCheck }, { headers })
        .then(res => {
          if (res.data.resultCd === '0000') {
            // temp 업데이트
            axios
              .post("/api/profile/updateCurationTemp", { spaceId, carPurposeCheck }, { headers })
              .then(res => {
                if (res.data.resultCd === '0000') {
                  this.isAlertModal = true;
                  this.mainOnOff();
                }
              })
              .catch(e => { console.log(e); });
          }
        })
        .catch(err => {
          console.log(err);
        });
    },
    getCurationInfo () {
      var spaceId = localStorage.getItem("spaceId");
      var aToken = VueCookies.get("aToken");
      var headers = { "X-AUTH-TOKEN": aToken };

      axios
        .post('/api/profile/getCurationInfo', { spaceId }, { headers })
        .then(res => {
          if (res.data.resultCd === '0000') {
            this.carPurposeCheck = res.data.result.carPurposeCheck;
            this.mainOnOff();
          }
        })
        .catch(err => {
          console.log(err);
        });
    },
    linkMySpace () {
      this.$router.push({ name: "MySpace" });
    },
    clickPre () {
      history.back();
    },
    mainOnOff () {
      if (this.carPurposeCheck === 'Y') {
        $('.choice_date').show();
        // $('.auto_play_notice').show();
      } else {
        $('.choice_date').hide();
        // $('.auto_play_notice').hide();
      }
    }
  },
  created () {
    this.getCurationInfo();
  }
};
</script>

<style scoped>
.modal {
  display: block;
}

.auto_play_notice ul {
  padding: 0;
}

.carpurpose_form {
  max-width: 940px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}

.carpurpose_form button {
  width: 120px;
  height: 60px;
  border-radius: 8px;
  background: var(--secondary);
  color: #fff;
  font-size: 16px;
  line-height: 60px;
  font-weight: 500;
  transition: all 0.3s;
}

@media all and (max-width:500px) {
  .form_bottom button {
    text-align: center;
    background: #FF2D55;
  }

  .carpurpose_form {
    position: fixed;
    bottom: 0;
    padding: 16px 5%;
  }

  .carpurpose_form button {
    width: 100%;
  }
}
</style>

<style src="@/assets/css/content2.css"></style>
<style src="@/assets/css/font.css"></style>
